.App {
    display: flex;
}

.container {
    display: inline;
     text-align: left;
/*app*/
    flex-direction: column;
    /*font-family: Rubik;*/
/*fill*/
    display: flex; 
    flex-direction: column;
    justify-content: center;
    flex: 1; 
    word-break: break-word !important;
    align-items: normal !important;
    /* padding-right: 2%; */
}

.fill {
}

.navbar {
    align-items: normal !important;
}


.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 17px;
}


.form {
    /* padding-top: 1%; */
    /* padding-bottom: 1%; */
    font-size: 27px;
}

.output {
    /* padding-left: 10%;
    padding-right: 25%;*/
    padding-top: 1%;
    padding-bottom: .5%;
    padding-right: 5%;
    padding-left: 5%;
} 

.output h1 {
    font-size: 30px;
    color: #333333; 
}


.output p {
    font-size: 24px;
    font-weight: normal;
    color: #333333;
}

/* .inlineoutput {
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: row;
}  */

.chattext {
    padding-left: 4%;
    /* padding-right: 10%; */
    padding-top: 4%;
    padding-bottom: 4%;
}

.botchat {
    /* padding-left: 10%; */
    padding-right: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.padsir {
    /* padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%; */
}


  .chatbubbleyou {
    /* border-radius: 15px; */
    /* margin-left: 15%; */
    /* margin-right: 5%; */
    margin-bottom: 1%;
    /* border:1px solid black; */
  }


  .chatbubblebot {
    /* background-color: white; */
    border-radius: 15px;
    /* margin-right: 15%; */
    margin-bottom: 1%;
    /* margin-top: 1%; */
    /* border:1px solid black; */
  }

  .chatbubblerec {
    /* background-color: white; */
    border-radius: 15px;
    /* margin-right: 15%; */
    /* margin-bottom: 1%; */
    margin-top: 2%;
    /* border:1px solid black; */
  }

  .chatbubbleoptions {
    background-color: lightgrey;
    border-radius: 30px;
    margin-right: 1.5%;
    margin-top: 1%;
    border:1px solid black;
    flex: 1;


    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .chatbubbleoptions:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}


  .optionsContainer {
    display: flex;
    /* flex-wrap: wrap; */
        /* flex-wrap: wrap;
        flex-direction: row-reverse; */
    flex-direction: row;
    min-width: 0;
    min-height: 0;
    overflow: hidden;
    margin-right: 5%;

    text-align: center;
    /* align-items: center; */
    justify-content: center;
  }
  .popover {
    /* prevents vertical stretching */  
    align-self: center;  
  }
  
  .pointer {
    cursor: pointer;
  }

/* 
.bubble-bottom-left:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 24px solid #fff;
    border-right: 12px solid transparent;
    border-top: 12px solid #fff;
    border-bottom: 20px solid transparent;
    left: 32px;
    bottom: -24px;
  } */
  
  
